<script>
import { mapGetters, mapMutations } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			indexSelected: 0,
			formData: {
				business_risk_assessment: '',
				subactivities: [
					{
						sector_bbva: 'Transportation',
						sub_sector_bbva: 'Logistic services',
						activity_bbva: 'Logistic services',
						sub_activity_bbva: 'Logistic and Air Freight',
						template: 'Generic',
						country: 'Mexico',
						sales: 100,
						industry_risk_score: 5.5,
						final_industry_risk_score: 6.5,
						indutry_risk_valuation: 'Riesgo intermedio',
						industry_risk_comments: 'Riesgo intermedio',
						// 	COMPETITIVE ADVENTAGE
						market_position: 7.5,
						// SCALE, SCOPE AND DIVERSITITY
						diversification: 7.5,
						// OPERATING EFFICIENCY
						operating_efficiency: 7.5,
						// COMPETITIVE ASSESSMENT
						competitive_valuation: 'Week',
						competitive_assessment: '...'
					}
				],
			},
			chartCountries: {
				series: [100],
				legend: {
					floating: true
				},
				chartOptions: {
					labels: ['Mexico',],
					colors: ['#D8BE75'],
					chart: {
						type: 'donut',
					},
					plotOptions: {
						pie: {
							donut: {
								size: '65%'
							},
							size: 200
						},
						
						legend: {
							width: 200,
						}
					}
				},
			},
			chartActivity: {
				series: [100],
				
				plotOptions: {
					pie: {
						donut: {
							size: '65%'
						},
						size: 200
					},
					
					legend: {
						position: 'bottom',
						floating: true
					}
				},
				chartOptions: {
					labels: ['Logistic servicie',],
					colors: ['#D8BE75'],
					chart: {
						type: 'donut',
					},
					
					plotOptions: {
						pie: {
							donut: {
								size: '65%'
							},
							size: 200
						},
						
						legend: {
							width: 200,
						}
					}
				},
				
			}
		}
	},

	computed: {
		...mapGetters({
			'ratingSelected': 'arceRatings/selected'
		})
	},

	
	methods: {
		calcFill() {
			let fields = Object.keys(this.form)
			if(this.form.multipleGeografia == 'NO') {
				let index = fields.indexOf('comentariosMultipleGeografia')
				fields.splice(index, 1)
			}
			if(this.form.multipleActividadEconomica == 'NO') {
				let index = fields.indexOf('comentariosMultipleActividadEconomica')
				fields.splice(index, 1)
			}
			let points = 100 / fields.length

			let fill = 0

			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		save() {
			let vm = this
			let payload =  {
				key: 'business_risk',
				form: {...this.formData},
				data: {},
			}
			vm.updateAnalysis(payload)
			this.hideModal('#formBusinessRiskModal')
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},
		hideModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).modal('hide')
			}
		},
		closeModal() {
			this.updateFormData()
		},
		updateFormData() {
			let formData = JSON.parse(JSON.stringify(this.ratingSelected.analysis.business_risk.form))
			this.$set(this, 'formData', formData)
		},
		...mapMutations({
			updateAnalysis: 'arceRatings/updateAnalysis'
		}) 
		
	},
	mounted() {
		this.updateFormData()
		$('#formBusinessRiskModal').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#formBusinessRiskModal').off('hide.bs.modal', this.closeModal)
	}
	
}
</script>
<template>
	<div style="">
		
		<div  v-if="formData" class="py-3">
			<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_n_1" aria-expanded="true" aria-controls="c_br_n_1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Sumary</span></h6>
				</div>
			</div>
			<div id="c_br_n_1" class="collapse show" aria-labelledby="headingOne">
				<div class="d-flex justify-content-center">
					<div style="width: 300px">
						<p class="text-center m-0">Activity distribution</p>
						<apexchart type="donut" :options="chartActivity.chartOptions" :series="chartActivity.series"></apexchart>
					</div>
					<div style="width: 255px">
						<p class="text-center m-0">Geographic distribution</p>
						<apexchart  type="donut" :options="chartCountries.chartOptions" :series="chartCountries.series"></apexchart>
					</div>
				</div>
				<div class="col-12 d-flex justify-content-center align-items-center">
					<div class="col-6 py-3">
						<p class="m-0">Business Risk Assessment</p>
						<textarea class="form-control" type="text" v-model="formData.business_risk_assessment"> </textarea>
					</div>
					<div>
						<button class="btn btn-transparent" @click="save"><SvgIcon name="save"></SvgIcon></button>
					</div>
				</div>
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__body ">
							<div class="text-center" data-v-step="ad7" style="white-space: nowrap; overflow: auto">
								<div class="col-3 px-1 d-inline-block" v-for="(item, index) in formData.subactivities" :key="`sub-${index}`">
									<div class="border p-3 s-arce__client" :class="{'s-arce__client--selected': index == indexSelected}">
										<!-- <h5>{{group.name}}</h5> -->
										<H6 class="mb-0">{{ item.sub_activity_bbva }}</H6>
										<div class="px-2">
											<p class="mb-1">Country</p>
											<p class="mb-1">{{ item.country }}</p>
											<p class="mb-1">Activity</p>
											<p class="mb-1">{{ item.activity_bbva}}</p>
											<p class="mb-1">Sector</p>
											<p class="m-1">{{ item.sector_bbva}}</p>
											<p class="mb-1">Template</p>
											<p class="m-1">{{ item.template}}</p>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				
			</div>
			<div class="text-right py-2">
				<button class="s-btn s-btn--primary" @click="showModal('#formBusinessRiskModal')">
					Edit
				</button>
			</div>
			<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_n_2" aria-expanded="true" aria-controls="c_br_n_2">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Industry Risk</span></h6>
				</div>
			</div>
			<div id="c_br_n_2" class="collapse show" aria-labelledby="c_br_n_2">
				<div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">Industry risk score</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.business_risk.form.subactivities[indexSelected].industry_risk_score">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Final industry riks score</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.business_risk.form.subactivities[indexSelected].final_industry_risk_score">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Industry risk valuation</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.business_risk.form.subactivities[indexSelected].indutry_risk_valuation">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Industry risk comments</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.business_risk.form.subactivities[indexSelected].industry_risk_comments">
					</div>
				</div>
			</div>

			<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_n_3" aria-expanded="true" aria-controls="c_br_n_3">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Competitive Position</span></h6>
				</div>
			</div>
			<div id="c_br_n_3" class="collapse show" aria-labelledby="c_br_n_3">
				<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_n_3_1" aria-expanded="true" aria-controls="c_br_n_3_1">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>COMPETITIVE ADVENTAGE</span></h6>
					</div>
				</div>
				<div id="c_br_n_3_1" class="collapse show" aria-labelledby="c_br_n_3_1">
					<div class="d-flex flex-wrap">
						<div class="col-4 py-3">
							<p class="m-0">Market Position</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.business_risk.form.subactivities[indexSelected].market_position">
						</div>
						
					</div>
				</div>
				
				<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_n_3_2" aria-expanded="true" aria-controls="c_br_n_3_2">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>SCALE SCOPE AND DIVERSITY</span></h6>
					</div>
				</div>
				<div id="c_br_n_3_2" class="collapse show" aria-labelledby="c_br_n_3_2">
					<div class="d-flex flex-wrap">
						<div class="col-4 py-3">
							<p class="m-0">Diversification</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.business_risk.form.subactivities[indexSelected].diversification">
						</div>
						
					</div>
				</div>
				
				<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_n_3_3" aria-expanded="true" aria-controls="c_br_n_3_3">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>OPERATING EFFICIENCY</span></h6>
					</div>
				</div>
				<div id="c_br_n_3_3" class="collapse show" aria-labelledby="c_br_n_3_3">
					<div class="d-flex flex-wrap">
						<div class="col-4 py-3">
							<p class="m-0">Operating Efficiency</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.business_risk.form.subactivities[indexSelected].operating_efficiency">
						</div>
					</div>
				</div>
				
				<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_n_3_3" aria-expanded="true" aria-controls="c_br_n_3_3">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>COMPETITIVE ASSESSMENT</span></h6>
					</div>
				</div>
				<div id="c_br_n_3_3" class="collapse show" aria-labelledby="c_br_n_3_3">
					<div class="d-flex flex-wrap">
						<div class="col-4 py-3">
							<p class="m-0">Competitive Valuation</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.business_risk.form.subactivities[indexSelected].competitive_valuation">
						</div>
						<div class="col-4 py-3">
							<p class="m-0">Competitive Assessment</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.business_risk.form.subactivities[indexSelected].competitive_assessment">
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formBusinessRiskModal" tabindex="-1" role="dialog" aria-labelledby="formBusinessRiskModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_m_2" aria-expanded="true" aria-controls="c_br_m_2">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Industry Risk</span></h6>
						</div>
					</div>
					<div id="c_br_m_2" class="collapse show" aria-labelledby="c_br_m_2">
						<div class="d-flex flex-wrap">
							<div class="col-4 py-3">
								<p class="m-0">Industry risk score</p>
								<input class="form-control" type="text" v-model="formData.subactivities[indexSelected].industry_risk_score">
							</div>
							<div class="col-4 py-3">
								<p class="m-0">Final industry riks score</p>
								<input class="form-control" type="text" v-model="formData.subactivities[indexSelected].final_industry_risk_score">
							</div>
							<div class="col-4 py-3">
								<p class="m-0">Industry risk valuation</p>
								<input class="form-control" type="text" v-model="formData.subactivities[indexSelected].indutry_risk_valuation">
							</div>
							<div class="col-12 py-3">
								<p class="m-0">Industry risk comments</p>
								<textarea class="form-control" type="text" v-model="formData.subactivities[indexSelected].industry_risk_comments"> </textarea>
							</div>
						</div>
					</div>

					<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_m_3" aria-expanded="true" aria-controls="c_br_m_3">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Competitive Position</span></h6>
						</div>
					</div>
					<!-- MODEL FINAL -->
					<div id="c_br_m_3" class="collapse show" aria-labelledby="c_br_m_3">
						<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_m_3_1" aria-expanded="true" aria-controls="c_br_m_3_1">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>COMPETITIVE ADVENTAGE</span></h6>
							</div>
						</div>
						<div id="c_br_m_3_1" class="collapse show" aria-labelledby="c_br_m_3_1">
							<div class="d-flex flex-wrap">
								<div class="col-4 py-3">
									<p class="m-0">Market Position</p>
									<input class="form-control" type="text" v-model="formData.subactivities[indexSelected].market_position">
								</div>
								
							</div>
						</div>
						
						<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_m_3_2" aria-expanded="true" aria-controls="c_br_m_3_2">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>SCALE SCOPE AND DIVERSITY</span></h6>
							</div>
						</div>
						<div id="c_br_m_3_2" class="collapse show" aria-labelledby="c_br_m_3_2">
							<div class="d-flex flex-wrap">
								<div class="col-4 py-3">
									<p class="m-0">Diversification</p>
									<input class="form-control" type="text" v-model="formData.subactivities[indexSelected].diversification">
								</div>
								
							</div>
						</div>
						
						<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_m_3_3" aria-expanded="true" aria-controls="c_br_m_3_3">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>OPERATING EFFICIENCY</span></h6>
							</div>
						</div>
						<div id="c_br_m_3_3" class="collapse show" aria-labelledby="c_br_m_3_3">
							<div class="d-flex flex-wrap">
								<div class="col-4 py-3">
									<p class="m-0">Operating Efficiency</p>
									<input class="form-control" type="text" v-model="formData.subactivities[indexSelected].operating_efficiency">
								</div>
							</div>
						</div>
						
						<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_br_m_3_3" aria-expanded="true" aria-controls="c_br_m_3_3">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>COMPETITIVE ASSESSMENT</span></h6>
							</div>
						</div>
						<div id="c_br_m_3_3" class="collapse show" aria-labelledby="c_br_m_3_3">
							<div class="d-flex flex-wrap">
								<div class="col-4 py-3">
									<p class="m-0">Competitive Valuation</p>
									<input class="form-control" type="text" v-model="formData.subactivities[indexSelected].competitive_valuation">
								</div>
								<div class="col-4 py-3">
									<p class="m-0">Competitive Assessment</p>
									<input class="form-control" type="text" v-model="formData.subactivities[indexSelected].competitive_assessment">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
			</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.charts{
	max-height: 125px !important;
	min-height: 125px !important;
	height: 125px !important;
}
</style>